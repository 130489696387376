import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Frontend from "../views/Frontend";
import Backend from "../views/Backend";
import Mobile from "../views/Mobile";
import Product from "../views/Product";
import Cloud from "../views/Cloud";
import UIUX from "../views/UIUX";
import Digital from "../views/Digital";
import Web from "../views/Web";
import BusinessIntelligence from "../views/BusinessIntelligence";
import ContactUs from "../views/ContactUs";
import Contact from "../views/Contact";
import Careers from "../views/Careers";
import Dashboard from "../views/admin/Dashboard";
import JobListing from "../views/admin/JobListing";
import CreateJob from "../views/admin/CreateJob";
import EditJob from "../views/admin/EditJob";
import Login from "../views/admin/Login";
import Projects from "../views/Projects";
import Subsidiaries from "../views/Subsidiaries";
import AtmosCaseStudy from "../views/AtmosCaseStudy";
import EsgtreeCaseStudy from "../views/EsgtreeCaseStudy";
import BigscootsCaseStudy from "../views/BigscootsCaseStudy";
import ConduktorCaseStudy from "../views/ConduktorCaseStudy";
import SimiConnectCaseStudy from "../views/SimiConnectCaseStudy";
import BlingRockCaseStudy from "../views/BlingRockCaseStudy";
import ZnapShotCaseStudy from "../views/ZnapShotCaseStudy";
import PrimeroCaseStudy from "../views/PrimeroCaseStudy";
import CareerFair from "../views/CareerFair";
import BebambiCaseStudy from "../views/BebambiCaseStudy.vue";
import BaykedCaseStudy from "../views/BaykedCaseStudy.vue";
import GtensorCaseStudy from "../views/GtensorCaseStudy.vue";
import ReadyCaseStudy from "../views/ReadyCaseStudy.vue";
import BigscootUI from "../views/BigscootUI.vue";
import SerenibyCaseStudy from "../views/SerenibyCaseStudy.vue";
import BilalCaseStudy from "../views/BilalCaseStudy.vue";
import ListenCaseStudy from "../views/ListenCaseStudy.vue";
import SmartCaseStudy from "../views/SmartCaseStudy.vue";
import OurTeam from "../views/OurTeam.vue";
import ArtificialIntelligence from "../views/ArtificialIntelligence.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "Septem Systems – Leading Information Technology Company",
      metaTags: [
        {
          name: "description",
          content:
            "Septem Systems is a Leading Information Technology Company specializing in custom AI-based software and mobile app development to automate workflows and drive innovation.",
        },
      ],
      sections: {
        default: {
          title: "Septem Systems – Leading Information Technology Company",
          description:
            "Septem Systems is a Leading Information Technology Company specializing in custom AI-based software and mobile app development to automate workflows and drive innovation.",
        },
        services: {
          title: "Our Services – Septem Systems",
          description:
            "At Septem we offer top digital solutions: web, apps, cloud, and product development. Boost your online presence with advanced digital marketing strategies.",
        },
      },
    },
  },
  {
    path: "/about",
    name: "about",
    component: About,
    meta: {
      title: "About Septem Systems - Empowering Your Digital Success",
      metaTags: [
        {
          name: "description",
          content:
            "Septem is a global software services company offering business consulting, IT, and best digital creativity, providing AI-based solutions ",
        },
      ],
    },
  },
  {
    path: "/frontend",
    name: "Frontend",
    component: Frontend,
  },
  {
    path: "/website",
    name: "Website",
    component: Web,
  },
  {
    path: "/backend",
    name: "Backend",
    component: Backend,
  },
  {
    path: "/mobile",
    name: "Mobile",
    component: Mobile,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/cloud",
    name: "Cloud",
    component: Cloud,
  },
  {
    path: "/business-intelligence",
    name: "BusinessIntelligence",
    component: BusinessIntelligence,
  },
  {
    path: "/uiux",
    name: "UIUX",
    component: UIUX,
  },
  {
    path: "/digital",
    name: "Digital",
    component: Digital,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "Contact Us – Septem Systems",
      metaTags: [
        {
          name: "description",
          content:
            "Contact Septem Systems today to discuss your project. Fill out our form for better query response. Tel: +92-42-38347751, Email: info@septemsystems.com.",
        },
      ],
    },
  },
  {
    path: "/careers",
    name: "Careers",
    component: Careers,
    meta: {
      title: "Careers – Septem Systems",
      metaTags: [
        {
          name: "description",
          content:
            "Join Septem Systems' team of talented designers and developers, collaborating on challenging projects to create world-class software and lead in digital marketing.",
        },
      ],
    },
  },
  {
    path: "/bebambi",
    name: "Bebambi",
    component: BebambiCaseStudy,
  },
  {
    path: "/bigscoot-ui",
    name: "BigscootUI",
    component: BigscootUI,
  },
  {
    path: "/bayked",
    name: "Bayked",
    component: BaykedCaseStudy,
  },
  {
    path: "/gtensor",
    name: "Gtensor",
    component: GtensorCaseStudy,
  },
  {
    path: "/admin",
    // name: "admin",
    component: Dashboard,
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "",
        component: JobListing,
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "create-job",
        name: "create-job",
        component: CreateJob,
      },
      {
        path: "edit-job/:id",
        name: "edit-job",
        component: EditJob,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/admin/jobs/create',
  //   name: 'create-job',
  //   component: CreateJob,
  // //   meta: {
  // //     requiresAuth: true
  // // }

  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/axxios",
    name: "AtmosCaseStudy",
    component: AtmosCaseStudy,
  },

  {
    path: "/esgtree",
    name: "EsgtreeCaseStudy",
    component: EsgtreeCaseStudy,
  },

  {
    path: "/bigscoots",
    name: "BigscootsCaseStudy",
    component: BigscootsCaseStudy,
  },

  {
    path: "/conduktor",
    name: "ConduktorCaseStudy",
    component: ConduktorCaseStudy,
  },
  {
    path: "/SimiConnect",
    name: "SimiConnect",
    component: SimiConnectCaseStudy,
  },
  {
    path: "/znapshot",
    name: "ZnapShot",
    component: ZnapShotCaseStudy,
  },
  {
    path: "/ready",
    name: "Ready",
    component: ReadyCaseStudy,
  },
  {
    path: "/Primero",
    name: "Primero",
    component: PrimeroCaseStudy,
  },

  {
    path: "/portfolio",
    name: "Portfolio",
    component: Projects,
    meta: {
      title: "Portfolio and Case Studies – Septem Systems",
      metaTags: [
        {
          name: "description",
          content:
            "Discover top-tier software development and custom solutions at Septem Systems. Explore how our teams innovate with executives, entrepreneurs, and tech experts.",
        },
      ],
    },
  },
  {
    path: "/subsidiaries",
    name: "Subsidiaries",
    component: Subsidiaries,
  },
  {
    path: "/career-fair-2023",
    name: "CareerFair",
    component: CareerFair,
  },
  {
    path: "/our-team",
    name: "OurTeam",
    component: OurTeam,
  },
  {
    path: "/hire-talent",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/sereniby",
    name: "SerenibyCaseStudy",
    component: SerenibyCaseStudy,
  },
  {
    path: "/bilal",
    name: "BilalCaseStudy",
    component: BilalCaseStudy,
  },
  {
    path: "/listen",
    name: "ListenCaseStudy",
    component: ListenCaseStudy,
  },
  {
    path: "/smart-interview-coach",
    name: "SmartCaseStudy",
    component: SmartCaseStudy,
  },
  {
    path: "/artificial-intelligence",
    name: "ArtificialIntelligence",
    component: ArtificialIntelligence,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const updateMetaTags = (route) => {
  const nearestWithTitle = route.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = route.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  }

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (nearestWithMeta) {
    nearestWithMeta.meta.metaTags.forEach((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      document.head.appendChild(tag);
    });
  }

  // Handle section-specific meta tags
  const section = route.hash.substring(1); // Remove the '#' from hash
  if (section && nearestWithMeta && nearestWithMeta.meta.sections) {
    const sectionMeta =
      nearestWithMeta.meta.sections[section] ||
      nearestWithMeta.meta.sections.default;
    if (sectionMeta) {
      document.title = sectionMeta.title;
      const descriptionMeta = document.querySelector(
        'meta[name="description"]'
      );
      if (descriptionMeta) {
        descriptionMeta.setAttribute("content", sectionMeta.description);
      } else {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = sectionMeta.description;
        meta.setAttribute("data-vue-router-controlled", "");
        document.head.appendChild(meta);
      }
    }
  }
};

// Handle initial meta tags
router.beforeEach((to, from, next) => {
  updateMetaTags(to);
  next();
});

// Handle hash change for updating meta tags dynamically
router.afterEach((to) => {
  updateMetaTags(to);
});
export default router;
